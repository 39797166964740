import fetch from '../auth/FetchInterceptor';

const createUser = async (user) => {
  const result = await fetch({
    url: '/user',
    method: 'post',
    data: user,
  });
  return result.data;
};

const updateUser = async (user, uuid) => {
  const result = await fetch({
    url: `/user/${uuid}`,
    method: 'patch',
    data: user,
  });
  return result.data;
};

const findAll = async () => {
  const result = await fetch({
    url: '/user',
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  return result.data;
};

const findAllByName = async (name) => {
  const result = await fetch({
    url: `/user/search/${name}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  return result.data;
};

const findUserByUuid = async (uuid) => {
  const result = await fetch({
    url: `/user/${uuid}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  return result.data;
}

export const userService = {
  createUser,
  updateUser,
  findAll,
  findAllByName,
  findUserByUuid
};
