import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import { useEffect } from "react";
import { useUser } from "stores/user.store";
import { useAuth } from "stores/auth.store";

export const NavProfile = () => {
  const profileImg = "/img/avatars/img-default.png";
  const { logout, userId } = useAuth();
  const { user, getUserByUuid } = useUser();

  useEffect(() => {
    fetchUser(userId);
  }, [userId, getUserByUuid]);

  const fetchUser = async () => {
    if (!user) {
      await getUserByUuid(userId);
    }
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3 d-flex align-items-center">
            <h4 className="mb-0">{user?.name}</h4>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={1} onClick={logout}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sair da conta</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    user && (
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="profile">
            <Avatar src={profileImg} />
          </Menu.Item>
        </Menu>
      </Dropdown>
    )
  );
};

export default NavProfile;
