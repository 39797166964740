import fetch from "auth/FetchInterceptor";

const login = async (data) => {
  const result = await fetch({
    url: "/auth/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
  return result.data;
};

const authService = {
  login,
};

export default authService;
