import { userService } from "services/user.service";
import { create } from "zustand";

export const useUser = create((set, get) => ({
  loadingUser: false,
  errorUser: null,
  users: [],
  user: null,

  createUser: async (user) => {
    set({ loadingUser: true });
    try {
      const result = await userService.createUser(user);
      return result;
    } catch (e) {
      set({ errorUser: e, showErrorUserMessage: true });
    } finally {
      set({ loadingUser: false });
    }
  },
  updateUser: async (user, uuid) => {
    set({ loadingUser: true });
    try {
      const result = await userService.updateUser(user, uuid);
      return result;
    } catch (e) {
      set({ errorUser: e, showErrorUserMessage: true });
    } finally {
      set({ loadingUser: false });
    }
  },
  findAllByName: async (name) => {
    set({ loadingUser: true });
    try {
      const usersFound = await userService.findAllByName(name);
      set({
        users: usersFound,
      });
    } catch (e) {
      set({
        users: [],
        errorUser: e,
        showErrorUserMessage: true,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  findAll: async () => {
    set({ loadingUser: true });
    try {
      const usersFound = await userService.findAll();
      set({
        users: usersFound,
      });
    } catch (e) {
      set({
        users: [],
        errorUser: e,
        showErrorUserMessage: true,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  getUserByUuid: async (uuid) => {
    set({ loadingUser: true });
    try {
      const result = await userService.findUserByUuid(uuid);
      set({ user: result });
      return result;
    } catch (e) {
      set({
        user: [],
        errorUsers: e,
        showErrorUserMessage: true,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  removeErrorUser: () => set({ errorUser: null }),
}));
