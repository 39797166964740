import authService from '../services/auth.service';
import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { AUTH_STORAGE, AUTH_TOKEN, USER_NAME, USER_ID } from '../constants/AuthConstant';

export const useAuth = create(
  persist(
    (set, get) => ({
      loading: false,
      message: '',
      showMessage: false,
      userId: localStorage.getItem(USER_ID),
      userName: localStorage.getItem(USER_NAME),
      redirect: '/',
      token: localStorage.getItem(AUTH_TOKEN),

      showAuthMessage: (message) => {
        set({ message, showMessage: true, loading: false });
      },
      showLoading: () => {
        set({ loading: true });
      },
      hideAuthMessage: () => {
        set({ message: '', showMessage: false });
      },
      authenticated: (token) => {
        set({ loading: false, redirect: '/app/home', token: token });
      },
      login: async (body) => {
        set({ loading: true, showMessage: false });

        try {
          const result = await authService.login(body);

          set({ token: result.access_token, userId: result.userUuid, userName: result.name });

          window.localStorage.setItem(AUTH_TOKEN, result.access_token);
          window.localStorage.setItem(USER_ID, result.userUuid);
          window.localStorage.setItem(USER_NAME, result.name);

        } catch (e) {
          set({ message: e?.response?.data?.message, showMessage: true });
        } finally {
          set({
            loading: false,
          });
        }
      },
      logout: () => {
        set({ token: null, userId: null });
        localStorage.removeItem(AUTH_TOKEN);
        window.location.href = '/auth/login';
      },
    }),
    {
      name: AUTH_STORAGE,
    }
  )
);
