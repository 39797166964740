import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          exact
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import('./dashboard'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/timeline`}
          component={lazy(() => import('./timeline'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/lista-de-usuarios`}
          component={lazy(() => import('./user-list'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/cargos-e-permissoes`}
          component={lazy(() => import('./roles-permissions'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/supervisor-geral`}
          component={lazy(() => import('./general-supervisor'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/supervisor-anodizacao`}
          component={lazy(() => import('./anodizing-supervisor'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/supervisor-pintura`}
          component={lazy(() => import('./painting-supervisor'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recebimento-de-material`}
          component={lazy(() => import('./material-receiving'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recebimento-de-material/add/:uuid`}
          component={lazy(() => import('./material-receiving/material-check'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recebimento-de-material/print/:uuid`}
          component={lazy(() => import('./material-receiving/print'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recebimento-de-material/etapas`}
          component={lazy(() => import('./material-receiving/steps'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recebimento-de-material/add`}
          component={lazy(() => import(`./material-receiving/material-check`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/montagem`}
          component={lazy(() => import('./assembly'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/montagem/adicionar`}
          component={lazy(() => import('./assembly/add'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/montagem/adicionar/:mountingUuid`}
          component={lazy(() => import('./assembly/add'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/montagem/finalizado`}
          component={lazy(() => import('./assembly/finished'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/tanques`}
          component={lazy(() => import('./tanks'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/pintura/montagem`}
          component={lazy(() => import('./paint/mounting'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/pintura/montagem/:batchCode`}
          component={lazy(() => import('./paint/mounting/add'))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/pintura/embalagem/:batchCode/:uuidPaintingMounting`}
          component={lazy(() => import(`./paint/packaging/add`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/pintura/embalagem`}
          component={lazy(() => import(`./paint/packaging`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/pintura/conferencia/:batchCode/:uuidPaintingPackaging`}
          component={lazy(() => import(`./paint/conference/add`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/pintura/conferencia`}
          component={lazy(() => import(`./paint/conference`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist`}
          component={lazy(() => import('./checklist'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/selecao`}
          component={lazy(() => import('./checklist/select-form'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/controle-de-placas/:uuidChecklist`}
          component={lazy(() =>
            import('./checklist/components/ControlePlacas')
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/controle-de-placas/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() =>
            import('./checklist/components/ControlePlacas')
          )}
        />
        {/* <Route
          exact
          path={`${APP_PREFIX_PATH}/conferencia/adicionar?qrCode=:batchCode`}
          component={(() =>
            import("./conference/add")
          )}
        /> */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/consumo-de-tinta/:uuidChecklist`}
          component={lazy(() => import('./checklist/components/ConsumoTintas'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/consumo-de-tinta/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import('./checklist/components/ConsumoTintas'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/inspecao-geral/:uuidChecklist`}
          component={lazy(() => import('./checklist/components/InspecaoGeral'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/inspecao-geral/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import('./checklist/components/InspecaoGeral'))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/inspecao-geral/atividades/:uuidChecklist`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/inspecao-geral/atividades/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-distinta/atividades/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-distinta/atividades/:uuidChecklist`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-distinta/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() =>
            import(`./checklist/components/LimpezaCubeDistinta`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-distinta/:uuidChecklist`}
          component={lazy(() =>
            import(`./checklist/components/LimpezaCubeDistinta`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-simples/atividades/:uuidChecklist`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-simples/atividades/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-simples/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() =>
            import(`./checklist/components/LimpezaCubeSimples`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/limpeza-do-cube-simples/:uuidChecklist`}
          component={lazy(() =>
            import(`./checklist/components/LimpezaCubeSimples`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/inspecao-geral/atividades/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/inspecao-geral/atividades/:uuidChecklist`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/final-de-turno/atividades/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/final-de-turno/atividades/:uuidChecklist`}
          component={lazy(() => import(`./checklist/components/Atividades`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/final-de-turno/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() => import(`./checklist/components/FinalTurno`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/final-de-turno/:uuidChecklist`}
          component={lazy(() => import(`./checklist/components/FinalTurno`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/controle-de-montagem/:uuidChecklist`}
          component={lazy(() =>
            import('./checklist/components/ControleMontagem')
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/controle-de-montagem/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() =>
            import('./checklist/components/ControleMontagem')
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/tempo-de-parada-cube/:uuidChecklist`}
          component={lazy(() =>
            import('./checklist/components/TempoParadaCube')
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/checklist/tempo-de-parada-cube/:uuidChecklist/:uuidChecklistForm`}
          component={lazy(() =>
            import('./checklist/components/TempoParadaCube')
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/conferencia`}
          component={lazy(() => import(`./conference`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/conferencia/add/:batchCode`}
          component={lazy(() => import(`./conference/add`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/conferencia/add/:batchCode/detalhes/:uuid`}
          component={lazy(() => import(`./conference/detail`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/embalagem`}
          component={lazy(() => import(`./packaging/recent-packaging`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/embalagem/nova-embalagem`}
          component={lazy(() => import(`./packaging/new-package`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/embalagem/nova-embalagem/bancada/:value`}
          component={lazy(() => import(`./packaging/new-package/select-bench`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/embalagem/nova-embalagem/materiais/bancada/:number`}
          component={lazy(() =>
            import(`./packaging/new-package/materials-bench`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/embalagem/nova-embalagem/bancada/:number/:batchCode`}
          component={lazy(() => import(`./packaging/order-items`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/embalagem/nova-embalagem/bancada/:number/:uuid/detalhes/:uuidDetails`}
          component={lazy(() => import(`./packaging/item-details`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/desmontagem`}
          component={lazy(() => import(`./disassembly`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/desmontagem/tubao`}
          component={lazy(() => import(`./disassembly/bigTube`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/desmontagem/armazenamento-tubao`}
          component={lazy(() =>
            import(`./disassembly/bigTube/components/Storage`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/desmontagem/finalizado`}
          component={lazy(() => import(`./disassembly/finishDisassembly`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/changelog`}
          component={lazy(() => import(`./changelog`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/polimento`}
          component={lazy(() => import(`./polishing`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/escovacao`}
          component={lazy(() => import(`./brushing`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sublimacao`}
          component={lazy(() => import(`./sublimation`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/estampagem`}
          component={lazy(() => import(`./stamping`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/desplacagem`}
          component={lazy(() => import(`./deplating`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/status-pedido`}
          component={lazy(() => import(`./order-status`))}
        />
        <Redirect
          exact
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/recebimento-de-material`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
