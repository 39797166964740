import Loading from "components/shared-components/Loading";
import { PDF_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

export const PdfViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/recebimento-de-material/print/:batchCode`}
          component={lazy(() =>
            import("../app-views/material-receiving/print")
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(PdfViews);

