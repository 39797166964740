import { Layout } from "antd";
import Loading from "components/shared-components/Loading";
import React from "react";

import { useThemeSwitcher } from "react-css-theme-switcher";
import PdfViews from "views/pdf-views";

const { Content } = Layout;

export const PdfLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Content>
      <PdfViews />
    </Content>
  );
};

export default React.memo(PdfLayout);

