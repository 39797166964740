import React from 'react';
import { APP_NAME } from 'configs/AppConfig';
import { Typography } from 'antd';

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{' '}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> Todos
        direitos reservados.
      </span>
      <Typography
        style={{
          paddingLeft: '24px',
          paddingBottom: '10px',
          color: '#1a335399',
          textAlign: 'initial',
        }}
      >
        {`Versão: ${process.env.REACT_APP_VERSION || '1.0.0'}`}
      </Typography>
    </footer>
  );
}
