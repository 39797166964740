import Loading from "components/shared-components/Loading";
import { PDF_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

export const PdfViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/recebimento-de-material/print/:batchCode`}
          component={lazy(() =>
            import("../app-views/material-receiving/print")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/montagem/print/:montingUuid`}
          component={lazy(() =>
            import("./print/natural-reject-anoding")
          )}
        />

        <Route
          exact
          path={`${PDF_PREFIX_PATH}/rejeito-natural-anodizacao/print/:mountedProcedureUuid`}
          component={lazy(() =>
            import("./print/natural-reject-anoding-mounting")
          )}
        />

        <Route
          exact
          path={`${PDF_PREFIX_PATH}/rejeito-natural/print/:receiptProcedureUuid`}
          component={lazy(() =>
            import("./print/natural-rejected")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/rejeito-natural-pintura/print/:paintingMountingUuid`}
          component={lazy(() =>
            import("./print/natural-reject-painting-mounting")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/rejeito-natural/print/:receiptProcedureUuid`}
          component={lazy(() =>
            import("./print/natural-rejected")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/polimento/print/:polishingProcessUuid`}
          component={lazy(() =>
            import("./print/natural-reject-polishing")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/escovacao/print/:brushingProcessUuid`}
          component={lazy(() =>
            import("./print/natural-reject-brushing")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/embalagem/anodizacao/print/:receiptProcedureUuid`}
          component={lazy(() =>
            import("./print/reprocess-annoding-packaging")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/embalagem/pintura/print/:paintingPackagingUuid`}
          component={lazy(() =>
            import("./print/reprocess-paint-packaging")
          )}
        />
        <Route
          exact
          path={`${PDF_PREFIX_PATH}/reprocesso/print/:receiptProcedureUuid`}
          component={lazy(() =>
            import("./print/reprocess")
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(PdfViews);

